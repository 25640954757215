<template>
    <!-- <div>test</div> -->
    <div class="content">
        <img src="@/assets/image/aboutNagiLogo.png" class="logo" alt="">
        <div style="background: #dad7cc;width: 100vw;height: 32vw;">
            <div class="text">
                <p v-for="(item, index) in textData" :key="item">
                    <span v-if="text['text' + index].indexOf('#') > -1"> {{ text['text' + index].slice(text['text' +
                        index].indexOf('#') + 1, text['text' + index].lastIndexOf('#')) }}</span>
                    {{ text['text' + index].indexOf('#') > -1 ? text['text' + index].slice(text['text' +
                        index].lastIndexOf('#') + 1, text['text' + index].length) : text['text' + index] }}
                </p>
                <img class="linglan" src="@/assets/image/miniLinglan.png" alt="">
            </div>
            <div class="tip">
                <div class="test"
                    style="transform: translateY(-10vw);z-index: 2;position: relative;transition: 1s cubic-bezier(0, -0.06, .04, .83);opacity: 0;filter: blur(1vw);">
                    <span class="aboutText">about</span>
                    <img src="@/assets/image/NAGI'S_about.png" class="smallLogo" alt="">
                </div>
                <div class="test2"
                    style="z-index: 1;position: relative;transform: translateX(20vw);transition: 2s cubic-bezier(0, -0.06, .04, .83);opacity: 0;filter: blur(.3vw);">
                    <img src="@/assets/image/textBackground.png" class="textBackground" alt="">
                    <p><span style="display: block;margin-bottom: .3vw;">「Nagi」は自分の名前であり、</span><br> 「Nagi’ Choice」は
                        <br>これから始めようとすることの名前です。
                    </p>
                </div>
            </div>
        </div>
        <div style="position: absolute;width: 100vw;height: 44vw;background: #f6efe5;overflow: hidden;transition: 1s cubic-bezier(0, -0.06, .04, .83);backface-visibility: hidden;z-index: 1;"
            :class="isChange ? 'change' : 'changeT'">
            <img src="@/assets/image/rightXianGao.png" class="xiangao" alt="">
            <div
                style="position: absolute;background: #f2dad0;width: 100vw;height: 28vw;border-bottom: .1vw solid #bebdb5;">
            </div>
            <div class="conterText">
                <p v-for="item in conterText" :key="item" class="conterTextP">{{ item }}</p>
            </div>
            <div class="leftTextContent">
                <p v-for="item in leftTextContent" :key="item" class="leftTextContentP"
                    :style="`margin-top:${item.indexOf('■') > -1 ? '2' : '0'}vw`">
                    {{ item.indexOf('■') > -1 ? item.slice(item.indexOf('■') + 1, item.length) : item }}
                </p>
            </div>
            <div class="rightAllIcon">
                <p class="pIcon">「自分を知る旅をしてます。」</p>
                <img src="@/assets/image/NAGI'S_aboutTwo.png" class="tNagi" alt="">
                <img src="@/assets/image/changePage.png" class="changePage" @click="changePage()" alt="">
            </div>
            <img src="@/assets/image/door.png" style="
                width: 6.7vw;
                top: 32.2vw;
                position: absolute;
                left: 76.4vw;
                z-index: 100;
                transform-origin: right;
                transition: 1s ease;" alt="" @mouseenter.stop="hoverDoor()" @mouseleave.stop="hoverDoorFalse()"
                class="doorImg">
            <img src="@/assets/image/doorBack.png" class="doorImgBack" style="width: 9.8vw;
                top: 30.1vw;
                position: absolute;
                left: 75.1vw;
                z-index: 99" alt="">
        </div>
        <div style="position: absolute;width: 100vw;height: 44vw;background: #f0efe2;overflow: hidden;transition: 1s cubic-bezier(0, -0.06, .04, .83);transform: rotateY(180deg);"
            :class="isChange ? 'changeT' : 'change'">
            <div style="width: 100vw;height: 30vw;background: #bebdb5;position: absolute;bottom: 0;"></div>
            <div>
                <img src="@/assets/image/backTextBackground.png" class="backTextBackground" alt="">
                <div style="position: absolute;left: 58vw;font-size: 0.7vw;top: 9vw;">
                    <p v-for="item in backBorderText" :key="item">{{ item }}</p>
                </div>
            </div>
            <div class="backConterText">
                <p v-for="item in backConterText" :key="item">
                    {{ item }}
                </p>
            </div>
            <div class="backIcon">
                <img src="@/assets/image/leftHua.png" alt="" class="leftHua">
                <div
                    style="position: absolute;font-size: 3vw;left: 40vw;top: 4vw;color: #fff;opacity: .3;letter-spacing: .5vw;">
                    NAGI’S CHOICE</div>
                <div style="position: relative;top: -8vw;">
                    <span class="backAboutText">about</span>
                    <img src="@/assets/image/NAGI'S_about.png" class="smallLogo" alt="">
                    <p style="color: #5fa29e;font-size: .9vw;position: relative;top: -5vw;left: 10vw">でした。</p>
                </div>
                <img src="@/assets/image/backChangePage.png" alt="" @click="changePage()" class="backChangePage">
                <img src="@/assets/image/catIcon.png" alt="" class="catIcon">
            </div>
        </div>
        <index_bottom style="position: relative;top: 54vw;" />
    </div>
</template>
<script>
import index_bottom from './index_bottom.vue';
import contData from "@/components/aboutText";

export default {
    name: 'about_nagiName',
    components: {
        index_bottom
    },
    props: {
    },
    data() {
        return {
            textTest: '',
            textData: [
                'Nagi とは、',
                '風がやんで、波が穏やかになることを指す言葉。',
                "#Nagi's Choice# は、",
                '気持ちいい選択をする',
                'という意味が込められている。'
            ],
            text: {
                text0: '',
                text1: '',
                text2: '',
                text3: '',
                text4: '',
            },
            conterText: contData.conterText,
            leftTextContent: contData.leftTextContent,
            isChange: false,
            backConterText: contData.backConterText,
            backBorderText: contData.backBorderText,
        }
    },
    mounted() {
        const dom = document.getElementsByClassName('linglan')[0]
        const test = document.getElementsByClassName('test')[0]
        const test2 = document.getElementsByClassName('test2')[0]
        // const tipShow = document.getElementsByClassName('tipShow')[0]
        const conterTextPArr = document.getElementsByClassName('conterTextP')
        const leftTextContentPArr = document.getElementsByClassName('leftTextContentP')
        document.body.style.height = '100vw'
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
        this.textTest = this.textData.join('')
        setTimeout(() => {
            dom.style.opacity = '1'
            dom.style.scale = '1.5'
        }, 40 * this.textTest.length);
        setTimeout(() => {
            dom.style.scale = '1'
            test.style.transform = 'translateY(0vw)'
            test2.style.transform = 'translateX(0vw)'
            test2.style.filter = test.style.filter = 'blur(0)'
            test2.style.opacity = test.style.opacity = '1'
        }, 40 * this.textTest.length + 500);
        this.dazijiFunT()
        setTimeout(() => {
            Object.values(conterTextPArr).map((item, index) => {
                setTimeout(() => {
                    item.style.opacity = 1
                    item.style.filter = 'blur(0)'
                }, index * 500)
            })
        }, 40 * this.textTest.length + 1500);
        setTimeout(() => {
            Object.values(leftTextContentPArr).map((item, index) => {
                setTimeout(() => {
                    item.style.opacity = 1
                    item.style.filter = 'blur(0)'
                }, index * 500)
            })
        }, 40 * this.textTest.length + this.conterText.length * 500 + 1000);
    },
    methods: {
        dazijiFunT() {
            let i = 0
            let p = 0
            var data = 0
            var setId = setInterval(() => {
                if (i == data + this.textData[p].length) {
                    data += this.textData[p].length
                    p += 1
                }
                this.text['text' + p] += this.textTest[i]
                i++

                if (i >= this.textTest.length) clearInterval(setId)
            }, 40);
        },
        changePage() {
            this.isChange = !this.isChange
        },
        hoverDoor() {
            const dom = document.getElementsByClassName('doorImg')[0]
            dom.style.transform = 'rotateY(30deg)  skewY(-3deg)'
        },
        hoverDoorFalse() {
            const dom = document.getElementsByClassName('doorImg')[0]
            dom.style.transform = 'rotateY(0deg)'
        }
    }
}
</script>
<style scoped>
.door {
    position: absolute;
    top: 0;
}

.catIcon {
    position: absolute;
    z-index: 9;
    top: 12vw;
    width: 10vw;
    left: 70vw;
}

.backChangePage {
    position: relative;
    z-index: 9;
    top: -14vw;
    left: 18vw;
    width: 6vw;
}

.leftHua {
    margin-right: 30vw;
}

.backAboutText {
    position: relative;
    left: 5vw;
    top: -3vw;
    display: block;
    font-size: .9vw;
    font-weight: 600;
    color: #5fa29e;
}

.backIcon {
    position: relative;
    z-index: 9;
    /* background: red; */
    top: 15vw;
    text-align: center
}

.leftHua {
    width: 10vw;
}

.backTextBackground {
    position: absolute;
    width: 30vw;
    left: 53vw;
    top: 8vw;
}

.backConterText {
    text-align: left;
    /* margin: 0 auto; */
    top: 3vw;
    left: 40vw;
    font-size: .7vw;
    position: relative;
}

.textBackground {
    left: -7vw;
    width: 20vw;
    position: absolute;
    z-index: 1;
    top: -6vw;
}

.leftTextContent {
    z-index: 10;
    position: relative;
    text-align: left;
    left: 18vw;
    top: 2.5vw;
    font-size: .7vw;
    line-height: .6vw;
    color: #6a615b;
}

.pIcon {
    position: relative;
    top: 7vw;
    color: #7c7c7c;
    font-size: .7vw;
    text-shadow: 0.1vw 0.1vw .5vw #fff;
    left: 6vw;
}

.changePage {
    position: absolute;
    width: 10vw;
    top: 20vw;
    left: 3vw;
}

.change {
    animation: test 1s forwards;
}

.changeT {
    animation: testT 1s forwards;
}

@keyframes test {
    0% {
        transform: rotateY(0deg);
        filter: blur(0vw);

    }

    50% {
        filter: blur(.3vw);
    }

    100% {
        transform: rotateY(180deg);
        filter: blur(0vw);
    }
}

@keyframes testT {
    0% {
        transform: rotateY(180deg);
        filter: blur(0vw)
    }

    50% {
        filter: blur(.3vw)
    }

    100% {
        transform: rotateY(0deg);
        filter: blur(0vw)
    }
}

.tNagi {
    width: 5vw;
    left: 8vw;
    position: relative;
    top: 9vw;
}

.rightAllIcon {
    position: relative;
    z-index: 10;
    left: 60vw;
    top: -30vw;
    display: inline-block;
}

.leftTextContentP {
    opacity: 0;
    filter: blur(.3vw);
    transition: all 1s ease;
}

.content {
    position: relative;
    top: -1vw;
    background-color: #dad7cc;
    height: 100vw;
}

.conterText {
    position: relative;
    z-index: 9;
    font-size: .7vw;
    text-align: center;
    left: 28vw;
    top: 3vw;
    line-height: .6vw;
    color: #6a615b;
}

.conterTextP {
    opacity: 0;
    filter: blur(.3vw);
    transition: all 1s ease;
}

.logo {
    position: absolute;
    width: 40vw;
    z-index: 10;
}

.text {
    position: relative;
    left: 10vw;
    top: 5vw;
    font-size: .8vw;
}

.xiangao {
    width: 50vw;
    z-index: 10;
    float: right;
    position: relative;
}

.tip {
    /* background: #fff; */
    /* box-shadow: .1vw .1vw 1vw rgba(0, 0, 0, .1); */
    /* width: 20vw;
    height: 7vw; */
    position: absolute;
    right: 17vw;
    top: 20vw;
}

.tip p {
    text-align: center;
    font-size: .7vw;
    position: relative;
    top: -4vw;
    left: -3vw;
    z-index: 2;
}

.smallLogo {
    width: 7vw;
    position: relative;
    left: 5.5vw;
    top: -3vw;
    z-index: 2;
}

.aboutText {
    position: absolute;
    right: 3.5vw;
    top: -4vw;
    font-size: .9vw;
    color: #5fa29e;
    font-weight: 600;
}

.text p {
    text-align: center;
    font-weight: 600;
    color: #7b7e76;
    line-height: .9vw;
    font-size: .7vw;
}

.text p:nth-child(3) {
    margin-top: 2vw;
    /* color: #98bbb3; */
    /* cursor: pointer; */
}

.text p span {
    color: #98bbb3;
    /* cursor: pointer; */
}

.linglan {
    position: absolute;
    left: 47.5vw;
    width: 5vw;
    /* scale: 2; */
    /* text-align: center; */
    top: 10vw;
    opacity: 0;
    transition: all 1s ease
}
</style>
