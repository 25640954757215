// ▼ お支払い方法

// ・PAYPAL決済と銀行振込になります。振込先情報は購入完了メールに記載されております。


// ▼ ご注文時に気をつけていただきたいこと

// ・ご連絡先をお間違えのないように記入してください。

// ・ 複数のお品物をご購入の際は、まとめてのご購入をお願いいたします。
// 購入された商品はキャンセルキャンセル・変更はできません。
// 分けての支払いは同梱できません。

// ・ご注文いただいた際、自動返信の形でご注文確認メールが送信されます。
// こちらのメールが届かない場合ご注文を承れていない場合がございますので、
// 必ず問い合わせフォームにてご連絡下さい。


// ▼梱包・ラッピング

// オリジナルデザインケース＋薄葉紙のお届けとなります。
// 搬送や輸送原因で多少の傷や凹みが生じます、ご了承ください。
// ※ラッピングペーパーのみをお送りすることはできません。


// ▼返品・交換について

// 一つずつ手作業で制作しているため、色つきや風合いの個体差が生じる場合がございます。
// また、雰囲気が似ていますが、柄違いのアンティーク布を使う場合や、おなじ商品でも違うパーツを使うなど、
// 異なる表情をもたらす場合がございます。

// 「送付商品が異なる場合」や「商品が破損していた不良品の場合」は
// 返品・交換はご対応させて頂きますので、到着後48時間以内にご連絡ください。

// イメージ違いや注文品間違いなど、お客様のご都合による返品・交換は受け付
// けておりませんので、何卒よろしくお願い申し上げます。

// （アンティーク感を出すために行った風合いを出す作業や、手で加工した痕跡は、
// 不良品の類にはいませんのでご了承ください。）

// 痕跡ができるだけ少ないものを作ろうと心がけていますが、あくまでも手で加工したものなので、
// ご了承いただけると幸いです。

// その他は、お問い合わせにてお待ちしております。

const contData = [
    '▼ お支払い方法',

    '・PAYPAL決済と銀行振込になります。振込先情報は購入完了メールに記載されております。',


    '▼ ご注文時に気をつけていただきたいこと',

    '・ご連絡先をお間違えのないように記入してください。',

    '・ 複数のお品物をご購入の際は、まとめてのご購入をお願いいたします。',
    '購入された商品はキャンセルキャンセル・変更はできません。',
    '分けての支払いは同梱できません。',

    '・ご注文いただいた際、自動返信の形でご注文確認メールが送信されます。',
    'こちらのメールが届かない場合ご注文を承れていない場合がございますので、',
    '必ず問い合わせフォームにてご連絡下さい。',


    '▼梱包・ラッピング',

    'オリジナルデザインケース＋薄葉紙のお届けとなります。',
    '搬送や輸送原因で多少の傷や凹みが生じます、ご了承ください。',
    '※ラッピングペーパーのみをお送りすることはできません。',


    '▼返品・交換について',

    '一つずつ手作業で制作しているため、色つきや風合いの個体差が生じる場合がございます。',
    'また、雰囲気が似ていますが、柄違いのアンティーク布を使う場合や、おなじ商品でも違うパーツを使うなど、',
    '異なる表情をもたらす場合がございます。',

    '「送付商品が異なる場合」や「商品が破損していた不良品の場合」は',
    '返品・交換はご対応させて頂きますので、到着後48時間以内にご連絡ください。',

    'イメージ違いや注文品間違いなど、お客様のご都合による返品・交換は受け付',
    'けておりませんので、何卒よろしくお願い申し上げます。',

    '（アンティーク感を出すために行った風合いを出す作業や、手で加工した痕跡は、',
    '不良品の類にはいませんのでご了承ください。）',

    '痕跡ができるだけ少ないものを作ろうと心がけていますが、あくまでも手で加工したものなので、',
    'ご了承いただけると幸いです。',

    'その他は、お問い合わせにてお待ちしております。',
]

export default contData
