<template>
    <div class="box2">
        <div class="rotateBox2" @click="twoClick(3)">
        <shadow_name style="left: -50vw;" ref="shadow_name" :isLeft="false" />
            <!-- <shadow_name ref="shadow_name" /> -->
            <img src="@/assets/image/scroll.png" alt="" class="scroll">
            <div>
                <img src="@/assets/image/formImage.png" alt="" class="formImageBack">
            </div>
        </div>
        <div class="rotateBoxBack2" @click="twoClick(1)">
            <div class="rotateBack">
        <shadow_name  ref="shadow_name" :isLeft="true" />
                <div class="bar">
                    <img src="@/assets/image/allBar.png" alt="" class="allBar">
                </div>
                <div class="showImageBox">
                    <el-image :src="url" @click.stop="showScale()" fit="fill" class="showImg">
                    </el-image>
                </div>
                <img src="@/assets/image/lunaBackground.png" alt="" class="lunaBackground">
            </div>
        </div>
    </div>
</template>
<style scoped>
.rotateBox2 {
    width: 50vw;
    height: 100vh;
    background: #faf1d8;
    position: absolute;
    z-index: 1;
    transform-origin: left;
    transition: 1s ease;
    overflow: hidden;
    backface-visibility: hidden;
    /* box-shadow: -1vw 0vw 10vw rgba(0,0,0,.5); */
}

.rotateBoxBack2 {
    width: 50vw;
    height: 100vh;
    background: #a6998f;
    position: absolute;
    z-index: 0;
    transform-origin: left;
    transition: 1s ease;
    overflow: hidden;
    box-shadow: 1vw 0 1vw rgba(0, 0, 0, .5);
}

.box2 {
    transform-style: preserve-3d;
    perspective: 90vw;
    position: relative;
}

.showImageBox {
    position: absolute;
    top: 10vw;
    left: 10vw;
    mask-image: url('@/assets/image/mask.png');
    mask-repeat: no-repeat;
    mask-size: 17vw 23vw;
    background-color: #fff;
}

.showImg {
    position: relative;
    display: inline-block;
    transition: .5s ease;
}

.allBar {
    width: 49vw;
}

.lunaBackground {
    width: 20vw;
    position: absolute;
    top: 10vw;
    /* left: 20vw; */
    right: 0;
}

.scroll {
    width: 2vw;
    position: absolute;
    right: 5vw;
    top: 2vw;
}

.formImage {
    width: 40vw;
    position: absolute;
    bottom: 2vw;
    left: 5vw;
}

.formImageBack {
    width: 40vw;
    position: absolute;
    top: 40vw;
    right: 5vw;
}

.rotateBack {
    transform: rotateY(180deg);
}
</style>
<script>
import shadow_name from '../../components/shadow.vue'
export default {
    components: {
        shadow_name,
    },
    props: {
        srcList: {
            type: Array
        }
    },
    data() {
        return {
            url: require('@/assets/test/test.png'),
            index: '',
        }
    },
    watch: {
    },
    mounted() {
        this.$bus.$on('changeImage', (e) => {
            const dom = document.getElementsByClassName('showImg')[0]
            dom.style.filter = 'blur(.3vw)'
            this.index = e.index
            setTimeout(() => {
                this.url = this.srcList[e.index]
                dom.style.width = e.imgWidth
                dom.style.height = e.imgHeight
            }, 500);
            setTimeout(() => {
                dom.style.filter = 'blur(0vw)'
            }, 1000);
        })
        // this.$bus.$on('changeI')
    },
    methods: {
        twoClick(index) {
            this.$bus.$emit('testChange', index)
            // if(index==1){
            //     const dom = document.getElementsByClassName('box3')[0]
            //     dom.style.zIndex = '3'
            // }
            // //重要
            // let i =3
            // console.log(index);
            // const setId = setInterval(() => {
            //     this.$bus.$emit('testChange',i)
            //     i++
            //     if(i>5){
            //         clearInterval(setId)
            //     }
            // }, 200);
            // // end
        },
        showScale() {
            const data = {
                // show:this.url,
                index: this.index,
                list: [...this.srcList],
            }
            console.log(this.srcList);
            this.$bus.$emit('openImage', data)
            // this.$emit('scaleTure')
        },
    }
}
</script>