<template>
  <div style="width: 100vw;height: 100vh;background: rgba(0,0,0,0.5);z-index: 9999999;">
    <div class="image">
      <img :src="showImage" class="showImage" draggable="true" alt="">
      <p class="closeButton" @click="close()">x</p>
    </div>
  </div>
</template>
<style>
.closeButton {
  position: absolute;
  font-size: 2vw;
  top: -2vw;
  right: 2vw;
  cursor: pointer;
  color: #c8c7b3;
}

.showImage {
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100vw;
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: 50vh;
  transform: translate(0, -50%);
  transition: 1s ease;
  transform-origin: 32vw 0vh;
}

.image {
  width: 100vw;
  text-align: center;
  
}
.showImage{
  transition: all .2s ease;
  /* box-shadow:  */
}
</style>
<script>

export default {
  props: {
  },
  data() {
    return {
      showImage: '',
      list:[],
      index:'',
    }
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'auto' // 平滑滚动
    });
    this.$bus.$on('openImage', (e) => {
      this.showImage = e.list[e.index]
      this.list = e.list      
      this.index = e.index
      const dom2 = document.getElementsByClassName('scaleImage')[0]
      dom2.style.display = 'block'
      setTimeout(() => {
        dom2.style.opacity = '1'
      }, 10);
    })
    this.$bus.$on('changeMaxImage',(e)=>{
      const dom = document.getElementsByClassName('showImage')[0]
      dom.style.opacity = 0
      dom.style.filter = 'blur(.3vw)'
      setTimeout(() => {
        if(e == 'up' && this.index >0){
          this.showImage = this.list[this.index-1]
          this.index-=1
        }else if(e=='down' && this.index<this.list.length-1){
          this.showImage = this.list[this.index+1]
          this.index+=1
        }
      }, 200);
      setTimeout(() => {
        dom.style.opacity = 1
      dom.style.filter = 'blur(0vw)'
      }, 400);
      // }

    })
  },
  methods: {
    close() {
      // this.$emit('scaleFalse')
      const dom = document.getElementsByClassName('scaleImage')[0]
      dom.style.opacity = '0'
      setTimeout(() => {
        dom.style.display = 'none'
        this.$bus.$emit('closeImage')
      }, 1000);
    }
  }
}
</script>