<template>
    <div
        style="width: 80%;margin-left: 10%;font-size: .7vw;font-weight: 600;margin-top: 2vw;color: #4e4c4b;height: 150vh;">
        <div class="box1" style="height: 7vw;">
            <p style="color:#9c9074;display: inline-block;">ご注文内容</p>
            <div style="display: inline-block;float: right;position: relative;width: 70%;">
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 0;">
                    <p>商品合计</p>
                    <p style="float: right;">{{ '￥' + 222 }}</p>
                </div>
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 1vw;">
                    <p>送料</p>
                    <p style="float: right;">{{ '￥' + 222 }}</p>
                </div>
                <div class="smallBox"
                    style="position: absolute;width: 100%;left: 0;top: 2.5vw;border-bottom: .1vw solid #b3b3b3;height: 2vw">
                    <p style="color: #b3b3b3;font-weight: 100;">CIRCUS DOLLから直送</p>
                </div>
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 4vw;">
                    <p style="line-height: 2vw">お支払い金额</p>
                    <p style="float: right;font-size: 1vw;color: #9c9074;">{{ '￥' + 222 }}</p>
                </div>
            </div>
        </div>
        <div class="box2" style="border-bottom: .1vw solid #b3b3b3;width: 100%;">
            <p style="color:#9c9074;display: inline-block;">不支私い方法</p>
            <div style="display: inline-block;float: right;position: relative;width: 70%;">
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 0vw;">
                    <p>PAYPAL支払い</p>
                    <p style="float: right;">变更></p>
                </div>
            </div>
        </div>
        <div class="box3" style="width: 100%;height: 6vw;">
            <p style="color:#9c9074;display: inline-block;">专届叶先住所</p>
            <div style="display: inline-block;float: right;position: relative;width: 70%;">
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 0vw;">
                    <p>{{ '姓名' }}</p>
                    <p style="float: right;">变更></p>
                </div>
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 1vw;">
                    <p>{{ '116-23456' }}</p>
                </div>
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 2vw;">
                    <p>{{ '按实际的接口接口' }}</p>
                </div>
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 3vw;">
                    <p>{{ '啊实打实举报' }}</p>
                </div>
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 4vw;">
                    <p>{{ '07780423456' }}</p>
                </div>
            </div>
        </div>
        <div class="box4" style="border-bottom: .1vw solid #b3b3b3;width: 100%;">
            <p style="color:#9c9074;display: inline-block;">メ-ルアドレス</p>
            <div style="display: inline-block;float: right;position: relative;width: 70%;">
                <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: 0vw;">
                    <p>{{ 'sajjasjnjkhd@gmail.com' }}</p>
                    <p style="float: right;">变更></p>
                </div>
            </div>
        </div>
        <div class="box5" style="width: 100%;">
            <div class="goodsList" v-for="item in goodsList" :key="item">
                <div
                    style="display: inline-block;position: relative;width: 70%;overflow-wrap: break-word;word-break: break-all;margin-top: 1.5vw">
                    <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: -1vw;">
                        <p>{{ item.title }}</p>
                    </div>
                </div>
                <div
                    style="display: inline-block;position: relative;width: 70%;overflow-wrap: break-word;word-break: break-all;margin-top: 2vw">
                    <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: -1vw;">
                        <p>{{ '￥' + item.price }}</p>
                    </div>
                </div>
                <div
                    style="display: inline-block;position: relative;width: 70%;overflow-wrap: break-word;word-break: break-all;">
                    <div class="smallBox" style="position: absolute;width: 100%;left: 0;top: -1vw;">
                        <p style="font-weight: 600;">{{ '数量：' + item.num }}</p>
                    </div>
                </div>
                <br>
                <p style="display:inline-block;font-weight: 400;margin-top: 1vw;">CIRCUS DOLL</p><span
                    style="display: inline-block;float: right;margin-top: 1vw">{{ '小计￥' + '333' }}</span>
            </div>
        </div>
        <div class="box6" style="margin-top: 2vw;text-align: center">
            <button @click="$emit('ok')" style="background: #e1d0a7;border: none;color: #fff;border-radius: .2vw;width: 80%;height: 3vw;">ご注文確定</button>
            <p style="color: #e1d0a7;">‹ 入力画面に戻る</p>
        </div>
        <div style="margin-top: 2vw;color: #7e7e7d;">
            >> 特定商取引法に基づく表記
            <br>
             >> プライバシーポリシー
        </div>
    </div>
</template>
<style scoped>
.smallBox>p {
    display: inline-block;
    font-weight: 400;
}

.goodsList {
    border-bottom: .1vw solid #b3b3b3;
    height: 8vw;
}
</style>
<script>

export default {
    props: {
    },
    data() {
        return {
            goodsList: [
                {
                    url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                    num: 2,
                    title: '测试cecshi/test the text/jhjakjsjk/2134567862132',
                    price: '18000',
                },
                {
                    url: 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                    num: 1,
                    title: '测试cecshi/test the text/jhjakjsjk/2134567862132/第二个',
                    price: '18',
                },
                {
                    url: 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                    num: 1,
                    title: '测试cecshi/test the text/jhjakjsjk/2134567862132/第二个',
                    price: '18',
                },
            ],
        }
    },
    mounted() {

    },
    methods: {
    }
}
</script>
