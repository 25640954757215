const contData = {
    conterText: [
        '2016年  ビリビリというサイトで契約歌手として生放送活動していく。',
        '2019年  手作りを始め、撮影、PS/Pr編集や、手作り作者とコラボするなど。',
        '2023年  自分探しのために日本留学へ  同年中に日本語能力試験N1(最上級 )取得',
        '2024年  展示会通訳として活動'
    ],
    leftTextContent: [
        'こんにちは、',
        '自分探しているnagiです。',

        '■こんな風に自己紹介をしていたら、言語学校の先生に「遅れた青春期か」と、思いっきりツッコミされました笑',
        'たしかに今更感が半端なく、恥ずかしかったんですが、なぜ「今更自分探し」しているのか、多分、私の人生の',
        '空白期間が長かったからです。',

        '■少し重い話になりますが、高校時代は絵に書いたようないじめを受け、そこから外に出られないぐらい人と世界',
        'のことが怯え、不安と焦りばかり満ちている青春期を過ごしてきました。脳内のノイズを消すためには、寝るし',
        'かなかったので、毎日たくさん寝て、いつのまにか時間が過ぎさり、気がづけば、自分の人生だけ前に進んでい',
        'ないことに気づきました…。',

        '■経験、履歴、何にももっていない、外に出ることすら怖い24歳、その上心が病んでいる。最悪だと思いました。',

        '■「このままでいいのか」、「これが私が生きたい人生なのか」と、自分に問いかけて、怖かったことと少しずつ',
        '向き合おうと変えようとしてきました。',

        '■あれからずいぶん経ち、まだ理想な自分に成れていないのですが、やっと自分の人生時計が回り出した気がします。',
        'いろんな方と出会い、繋がりとモノに恵まれたおかげで、もしかしたら16歳の自分が想像できないような、そんな',
        'お姉さんになれたかなって、たまに思ったりします笑。',

        '■これからこそ遅れた青春期を大切したい、自分を知る旅を続けたいです。',
        '走らずとも、雨が降っていても、ただ楽しみたいと。',

        '■そんな思いを含め、Nagi‘s Choiceを始めました。'
    ],
    backConterText: [
        'ものが溢れる世の中で、たくさんの選択肢の中で、',
        'その「モノを買いたい欲望」は一体「社会的に構築された」ものなんでしょうか、',
        'それとも「本当に自分が好き」から来ているものなんでしょうか。',

        '自分自身に問いかけた時期がありました。',
    ],
    backBorderText: [
        '世間の価値観に流されることなく、',
        '本当に自分が好きなモノを作ってみたい、',

        'そんなコンセプトでNagi’s Choiceは始まりました。',

        'あなたのお気に入りが見つかるよう、',
        '「自分が好き」と思えるようなブランドづくりを目指したいと思います。',
    ]
}
export default contData