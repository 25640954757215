<template>
    <div class="circus" style="background: #fff4e5;width: 100vw;height: 230vw;overflow: hidden">
        <img src="@/assets/image/circusZp.png" class="zhangpeng" alt="">
        <img src="@/assets/image/leftTopIcon.png" class="leftTopIcon" alt="">
        <div class="textF">
            <p>「迷っている星でも、夜空を照らそうとする。」</p>
            <p style="margin-left: 3vw;">But are we all lost stars, </p>
            <p style="margin-left: 5vw;">trying to light up the dark.</p>
        </div>
        <img src="@/assets/image/book.png" alt="" class="book">
        <div class="book bookText">
            <p v-for="(item, index) in bookText" :style="`margin-top:${item.indexOf('■') > -1 ? '2' : '0'}vw`"
                :key="item">
                <span v-if="text['text' + index].indexOf('#') > -1"> {{ text['text' + index].slice(text['text' +
                    index].indexOf('#') + 1, text['text' + index].lastIndexOf('#')) }}</span>
                {{ text['text' + index].indexOf('#') > -1 ? text['text' + index].slice(text['text' +
                    index].lastIndexOf('#') + 1, text['text' + index].length) : text['text' + index] }}
            </p>
        </div>
        <p class="textT">「どんなモノを作ったらいいのかな？」</p>
        <div class="textTh">
            <img src="@/assets/image/redTextBorder.png" alt="" class="redTextBorder">
            <p class="redTextBorder textRed"> まず自分を喜ばせる作品 <br>たくさんを! </p>
        </div>
        <div class="rotateBox" :class="isChange ? 'change' : 'changeT'">
            <img src="@/assets/image/onBackground.png" class="onBackground" alt="">
            <div class="whiteTextBox">
                <img src="@/assets/image/whiteTextBorder.png" alt="" class="whiteTextBorder">
                <p class="whiteTextBorder whiteText">見ても恥ずかしく<br> 感じない作品を!</p>
            </div>
            <div class="conterText">
                <img src="@/assets/image/conterTextBackground.png" alt="" class="conterTextBackground">
                <p class="conterTextBackground conterTextP" v-for="item in conterText" :key="item">{{ item }}</p>
            </div>
            <img src="@/assets/image/shower.png" alt="" class="shower">
            <img src="@/assets/image/changeImage.png" alt="" class="changeImage" @click="changePage()">
        </div>
        <div class="rotateBoxBack" :class="isChange ? 'changeT' : 'change'">
            <div class="svg" style="position: absolute;top: -2vw;">
                <svg xmlns="http://www.w3.org/2000/svg" class="heng" viewBox="0 0 1000 100">
                    <path
                        d="M6441.53,6986.44l232.92-.93,231.72-.92,130.72-.52a1.13,1.13,0,0,0,0-2.25l-232.91.93-231.73.92-130.72.52a1.13,1.13,0,0,0,0,2.25Z"
                        transform="translate(-6440.45 -6981.82)" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="wan" viewBox="0 0 1400 1003">
                    <path
                        d="M7397.07,6987.77c8.41,1.72,17.31,3.27,25.85,1.54a25,25,0,0,0,10.94-5,26.46,26.46,0,0,0,7.42-10.33,57.38,57.38,0,0,0,3.55-13.2,134.66,134.66,0,0,0,1.58-15.26,245.94,245.94,0,0,0-.91-31.3c-1.67-20.89-4.55-41.91-2.35-62.88a78.19,78.19,0,0,1,2.57-14.32c1.26-4.16,3.31-8.32,6.9-10.95,3.9-2.86,8.86-3.44,13.56-3.52,5.42-.08,10.87.19,16.29.32l67.74,1.59,8.46.2a.75.75,0,0,0,0-1.5l-67.73-1.59-16.94-.4c-4.78-.11-9.65-.41-14.38.46a17.89,17.89,0,0,0-10.95,6.18,28,28,0,0,0-5,11.21c-2.35,9.72-2.84,20-2.76,30,.08,10.48,1,20.94,1.92,31.37,1.87,20.72,4.1,41.93.15,62.54-1.68,8.73-5,17.88-13.22,22.42-7.77,4.3-17.32,3.61-25.77,2.24-2.17-.35-4.33-.77-6.48-1.2-.95-.2-1.35,1.25-.4,1.44Z"
                        transform="translate(-7396.53 -6821.01)" />
                    <path
                        d="M7552,6824.46l207.57.78,206.51.77,116.5.44a.75.75,0,0,0,0-1.5l-207.57-.78-206.52-.78L7552,6823a.75.75,0,0,0,0,1.5Z"
                        transform="translate(-7396.53 -6821.01)" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="shu" viewBox="0 0 1000 100">
                    <path
                        d="M6441.53,6986.44l232.92-.93,231.72-.92,130.72-.52a1.13,1.13,0,0,0,0-2.25l-232.91.93-231.73.92-130.72.52a1.13,1.13,0,0,0,0,2.25Z"
                        transform="translate(-6440.45 -6981.82)" />
                </svg>
            </div>
            <img src="@/assets/image/titleImg.png" alt="" class="titleImg">
            <div class="changeBoxContentTextLeft">
                <p v-for="item in changeBoxContentTextLeft" :key="item">
                    {{ item }}
                </p>
            </div>
            <div class="changeBoxContentTextConter">
                <p v-for="item in changeBoxContentTextConter" :key="item">
                    {{ item }}
                </p>
            </div>
            <img src="@/assets/image/lunaLogo.png" alt="" class="lunaLogo">
            <div class="changeBoxContentRightTop">
                <p v-for="item in changeBoxContentRightTop" :key="item">
                    {{ item }}
                </p>
            </div>
            <div class="changeBoxContentTextRightBottom">
                <p v-for="item in changeBoxContentTextRightBottom" :key="item">
                    {{ item }}
                </p>
            </div>
            <div class="yinghaoLeft">
                <p style="font-family: fantasy"><i>“</i></p>
            </div>
            <div class="yinghaoRight">
                <p style="font-family: fantasy"><i>”</i></p>
            </div>
            <div class="allImage">
                <img src="@/assets/image/ren.png" alt="" class="ren">
                <img src="@/assets/image/doorLeft.png" @click="changePage()" alt="" class="doorLeft">
                <img src="@/assets/image/doorRight.png" alt="" class="doorRight">
                
                <img src="@/assets/image/doorBack.png" 
                class="doorImgBack" 
                alt="">
                <img src="@/assets/image/doorBack.png" 
                class="doorImgBack yinzi" 
                alt="">
                <img src="@/assets/image/door.png" alt="" class="door"   @mouseenter.stop="hoverDoor()" 
                @mouseleave.stop="hoverDoorFalse()" >
                <div class="yinzi dang">
                </div>
            </div>
        </div>
        <index_auto style="position: relative;top: 57vw;" :theName="theName"  />
    <index_bottom style="position: relative;top: 210vw" />
    </div>
</template>
<script>
import contData from "@/components/circurText"
import index_auto from "./index_auto.vue";
import index_bottom from "./index_bottom.vue";

export default {
    name: 'about_circusName',
    components:{
        index_auto,index_bottom
    },
    props: {
    },
    data() {
        return {
            isChange:false,
            theName:"about_circus",
            textTest: '',
            bookText: contData.bookText,
            changeBoxContentTextLeft:contData.changeBoxContentTextLeft,
            changeBoxContentTextConter:contData.changeBoxContentTextConter,
            changeBoxContentRightTop:contData.changeBoxContentTextRightTop,
            changeBoxContentTextRightBottom:contData.changeBoxContentTextRightBottom,
            text: {
                text0: '',
                text1: '',
                text2: '',
                text3: '',
                text4: '',
            },
            conterText: contData.conterText
        }
    },
    mounted() {
        document.body.style.height = '230vw'
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
        this.textTest = this.bookText.join('')
        this.dazijiFun()
    },
    methods: {
        changePage() {
            this.isChange = !this.isChange
        },
        dazijiFun() {
            let i = 0
            let p = 0
            var data = 0
            var setId = setInterval(() => {
                if (i == data + this.bookText[p].length) {
                    data += this.bookText[p].length
                    p += 1
                }
                this.text['text' + p] += this.textTest[i]
                i++
                if (i >= this.textTest.length) clearInterval(setId)
            }, 40);

        },
        hoverDoor(){
            const dom = document.getElementsByClassName('door')[0]
            const dom1 = document.getElementsByClassName('dang')[0]
            dom.style.transform = 'rotateY(30deg)  skewY(-3deg)'
            dom1.style.transform = 'translateX(2vw)  rotateX(100deg) skewX(15deg)'
        },
        hoverDoorFalse(){
            const dom = document.getElementsByClassName('door')[0]
            const dom1 = document.getElementsByClassName('dang')[0]
            dom.style.transform = 'rotateY(0deg) '
            dom1.style.transform = 'translateX(0vw)  rotateX(100deg) skewX(15deg)'
        }
    }
}
</script>
<style scoped>
.circus>* {
    position: absolute;
    font-size: .7vw;
}

.rotateBox {
    width: 100vw;
    top: 56vw;
    position: absolute;
    overflow: hidden;
    height: 57vw;
    transform: rotateY(0deg);
    backface-visibility: hidden;
    z-index: 9;
}

.rotateBoxBack {
    width: 100vw;
    top: 56vw;
    height: 57vw;
    position: absolute;
    overflow: hidden;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    z-index: 8;
    background: #d8cbbe;
}

.heng {
    position: absolute;
    width: 100vw;
    height: 20vw;
    left: -3vw;
    top: 9.7vw;
    /* background: yellow; */
}

.wan {
    position: absolute;
    width: 100vw;
    top: 3vw;
    left: 53vw;
}

.shu {
    position: absolute;
    width: 100vw;
    left: 40vw;
    height: 40vw;
    top: 30vw;
    transform: rotateZ(90deg);
}


path {
    stroke-width: 1;
    stroke: black;
    fill: none;
    stroke-dashoffset: 1200;
    stroke-dasharray: 1200;
    animation: testT 3s forwards;
}

.wan path {
    animation-delay: 1s;
}
.shu path{
    animation-delay: 1.5s;
}

@keyframes testT {
    0% {
        stroke-dashoffset: 1200;
        stroke-dasharray: 1200;
    }

    50% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1200;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 1200;
    }
}
.titleImg{
    width: 90vw;
    position: relative;
    top: 4vw;
}
.changeBoxContentTextLeft{
    position: absolute;
    font-weight: 600;
    top: 18vw;
    left: 5vw;
}
.changeBoxContentTextRightBottom{
    position: absolute;
    font-weight: 600;
    top: 25vw;
    left: 73vw;
    text-align: center;   
}
.changeBoxContentRightTop{
    position: absolute;
    font-weight: 600;
    top: 4vw;
    left: 59vw;   
}
.lunaLogo{
    position: absolute;
    left: 60vw;
    top: 15vw;
    width: 30vw;
}
.changeBoxContentTextConter{
    position: absolute;
    top: 18vw;
    font-weight: 600;
    left: 40vw; 
    text-align: center; 
}
.yinghaoRight{
    position: absolute;
    left: 88vw;
    top: 33vw;
    font-size: 5vw;
}
.yinghaoLeft{
    position: absolute;
    left: 69vw;
    top: 17vw;
    font-size: 5vw;
}
.allImage{
    position: absolute;
    top: 0;
    left: 10vw;
}
.allImage img{
    width: 0;
}
.ren{
    position: absolute;
    width: 10vw !important;
    left: 35vw;
    top: 29vw;
}
.door{
    position: absolute;
    width: 6.7vw !important;
    left: 56.3vw;
    top: 42.2vw;
    transition: 1s ease;
    transform-origin: right;
    z-index: 999;
}
.doorImgBack{
    position: absolute;
    width: 9.8vw !important;
    left: 55vw;
    top: 40.2vw;
}

.yinzi{
    transform: rotateX(100deg) skewX(15deg);
    filter: blur(.4vw);
    top: 46.5vw;
    left: 53.9vw;
}
.dang{
    background: #d8cbbe;
    filter: blur(0vw);
    /* background: red; */
    height: 10vw;
    width: 8vw;
    position: absolute;
    top: 48.19vw;
    left: 55.3vw;
    transition: 1s ease;
    /* transform: ; */
}
.doorLeft{
    position: absolute;
    width: 7vw !important;
    left: 47vw;
    top: 45vw;
}
.doorRight{
    position: absolute;
    width: 2vw !important;
    left: 67vw;
    top: 50vw;
}
.whiteTextBorder {
    position: relative;
    top: 0vw;
    width: 10vw;
    z-index: 99;
}

.whiteTextBox {
    position: relative;
    top: -52vw;
    z-index: 99;
    left: 34vw;
}

.whiteText {
    top: -5.5vw;
    line-height: 1.5vw;
    left: 2.5vw;
    color: #fff;
    transform: rotateZ(-3deg);
}

.shower {
    position: relative;
    top: -44vw;
    width: 30vw;
    left: 35vw;
    z-index: 10;
    transition: 1s cubic-bezier(0, -0.06, .04, .83);
}

.onBackground {
    width: 100vw;
}

.conterText {
    position: absolute;
    /* text-align: center; */
    z-index: 9;
    width: 25vw;
    left: 37.5vw;
    top: 15vw;
}

.changeImage {
    left: 63vw;
    width: 8vw;
    position: absolute;
    top: 39vw;
}

.conterTextBackground {
    width: 25vw;
    position: absolute
}

.conterTextP {
    top: 1vw;
    position: relative;
    text-align: center;
    line-height: .5vw;
}

.redTextBorder {
    width: 10vw;
    position: absolute;
    top: 45vw;
    left: 50vw
}

.textRed {
    left: 50vw;
    transform: rotateZ(3deg);
    line-height: 1.5vw;
    top: 45.4vw;
    text-align: center;
}

.zhangpeng {
    width: 100vw;
    position: absolute;
}

.leftTopIcon {
    width: 10vw;
    position: absolute;
    top: 5vw;
    left: 20vw;
}

.textF {
    left: 33vw;
    top: 10vw;
    color: #f57551;
    position: relative;
    font-weight: 600;
}

.book {
    width: 20vw;
    left: 45vw;
    top: 20vw;
}

.bookText {
    /* font-size: .; */
    left: 50vw;
    top: 23vw;
    line-height: .5vw;
}

.bookText span {
    color: #f57551;
}

.textT {
    left: 40vw;
    top: 40vw;
}

.change {
    animation: test 1s forwards;
}

.changeT {
    animation: testT 1s forwards;
}

@keyframes test {
    0% {
        transform: rotateY(0deg);
        filter: blur(0vw);
        
    }

    50% {
        filter: blur(.3vw);
    }

    100% {
        transform: rotateY(180deg);
        filter: blur(0vw);
    }
}

@keyframes testT {
    0% {
        transform: rotateY(180deg);
        filter: blur(0vw)
    }

    50% {
        filter: blur(.3vw)
    }

    100% {
        transform: rotateY(0deg);
        filter: blur(0vw)
    }
}

</style>
