<template>
    <div style="background: #eae6d9;width: 50vw;height: 100vh;z-index: -1;overflow: hidden;
    ">
        <shadow_name  ref="shadow_name" :isLeft="true"  />
        <div class="nameCard" style="position: absolute;width: 22vw;left: 14vw;top: 5vw;">
            <div class="lunaCard">
                <img src="@/assets/image/lunaCard.png" alt="" class="lunaCardBackground">
            </div>
            <div class="nagiCard">
                <img src="@/assets/image/nagiCard.png" alt="" class="nagiCardBackground">
            </div>
        </div>
    </div>
</template>
<style scoped>
.nagiCard,
.lunaCard {
    width: 30vw;
    height: 12vw;
    /* background: #aba097; */
    /* mask-image: url('@/assets/image/door.png');
    mask-repeat: no-repeat; */
}

img {
    width: 22vw
}
</style>
<script>
import shadow_name from '../../components/shadow.vue'
export default {
    components: {
        shadow_name
    },
    props: {
    },
    data() {
        return {
        }
    },
    mounted() {

        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
    },
    methods: {
    }
}
</script>