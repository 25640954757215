<template>
    <div class="maxBox">
        <div>
            <div style="width:100vw;height:100vh;background: yellow">
                <div class="leftPage">
                    <galary_first ref="first" class="page1" />
                </div>
                <div class="rightPage">
                    <galary_five ref="five" style="position: absolute;" class="page5" />
                    <galary_four ref="four" style="position: absolute;" class="page4" />
                    <galary_three class="page3" ref="three" style="position: absolute;" :srcList="srcList" />
                    <galary_two @click="testFun()" ref="two" class="page2" style="position: absolute;"
                        :srcList="srcList" />
                </div>
            </div>
        </div>
        <div class="index" style="width: 100vw;height: 100vh" @click.stop="donghua()">
            <img src="@/assets/image/galleryBackground.png" style="position: absolute;width: 100vw;" alt=""
                class="galaryBackground">
            <img src="@/assets/image/doorLine.png" alt="" class="doorLine">
            <img src="@/assets/image/doorLine.png" alt="" class="line">
            <div class="yinzi"></div>
            <!-- <img src="@/assets/image/doorBackWhite.png" alt="" class="doorBackWhite"> -->
            <div class="door">
                <img @mouseover="hoverDoor()" @mouseout="outDoor()" src="@/assets/image/door.png" alt=""
                    class="doorImage">
            </div>
            <div class="nameText" style="position: relative;margin: 0vw auto;width: 15vw;height: 15vw">
                <p style="left: 0;">N</p>
                <p style="right: 0">A</p>
                <p style="left: 0;margin-top: 14vw;">L</p>
                <p style="right: 0;margin-top: 14vw;">U</p>
                <div class="smallText">
                    ’s<br>
                    GALLERY
                </div>
            </div>
            <div class="tag">
                <span :class="'tagContent' + index + ' tagAllStyle'" v-for="(item, index) in tagArr" :key="item"
                    :style="`margin-left:${100 / (tagArr.length - 2) / 2}vw`">
                    {{ item }}
                </span>
            </div>
        </div>
        <scaleImage class="scaleImage" />
    </div>
</template>
<script>
import galary_first from './galary/galary_first.vue';
import galary_two from './galary/galary_two.vue';
import galary_three from './galary/galary_three.vue';
import galary_four from './galary/galary_four.vue';
import galary_five from './galary/galary_five.vue';
import scaleImage from '../components/scaleImage.vue'
export default {
    name: 'galary_indexName',
    components: {
        galary_first, galary_two, galary_three, scaleImage, galary_four, galary_five
    },
    props: {
    },
    data() {
        return {
            tagArr: [
                'test', 'test1', 'test2', 'test3', 'test4', 'test5', 'test6'
            ],
            setId: '',
            showImage: 'test',
            srcList: [
                // require('@/assets/test/test.png'),
                // require('@/assets/test/test.png'),
                // require('@/assets/test/test.png'),
                // require('@/assets/test/test3.png'),
                // require('@/assets/test/test4.jpg'),
                // require('@/assets/test/test2.png'),
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
                'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                'https://img1.baidu.com/it/u=2327100731,1655897337&fm=253&fmt=auto&app=120&f=JPEG?w=800&h=500',
                'https://img1.baidu.com/it/u=3121263691,2345547366&fm=253&fmt=auto?w=700&h=1005',
                'https://img2.baidu.com/it/u=952344434,469877813&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=832',
                'https://img0.baidu.com/it/u=3672090857,1850924585&fm=253&fmt=auto&app=138&f=JPEG?w=608&h=500',
            ],
            scrollStatus: '-1',//-1.无操作，0.正常翻页，1.放大图片，2.luna作品集，3.nagi作品集，4.名片，5.最后一页左滑
            page: 2,
            scrollY: 0,
            wheelIfStop: true,
            setTimeId: '',
            ifStop: true,
            copyStatus: '',
            status: 0,
        }
    },
    watch: {
        page(val) {
            if (val == 3) {
                this.scrollStatus = '2'
                document.body.style.height = '500vh'
            }
            if (val == 4) {
                this.scrollStatus = '3'
                document.body.style.height = '100vh'
            }
            if (val == 2) {
                this.scrollStatus = '0'
            }
            if (val == 5) {
                this.scrollStatus = '5'
                document.body.style.height = '500vh'
            }
        },
    },
    mounted() {
        this.$bus.$on('changeScrollStatus', (e) => {
            this.scrollStatus = e
        })
        this.$bus.$on('testChange', (e) => {
             this.testFun(e)
        })
        this.$bus.$on('openImage', () => {
            this.copyStatus = this.scrollStatus
            this.scrollStatus = '1'
        })
        this.$bus.$on('closeImage', () => {
            this.scrollStatus = this.copyStatus
        })
        window.addEventListener('wheel', () => {
            if (this.$router.currentRoute.fullPath == '/galary_index') {
                this.scrollFuncition(scrollY)
            }
        })
        this.tagArr.map((item, index) => {
            this.float(index);
        })
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
        window.addEventListener('wheel', (e) => {
            const ifUp = e.deltaY
            if (this.ifStop == true) {
                if (this.scrollStatus == '0') {
                    if (ifUp > 0 && this.page < 5) {
                        this.testFun(this.page + 1)
                    } else if (ifUp < 0 && this.page >= 2) {
                        console.log(this.page);
                        if (this.page == 2) {
                            this.testFun(this.page - 1)
                        } else {
                            this.testFun(this.page - 2)
                        }
                    }
                }
                if (this.scrollStatus == '1') {
                    console.log('sadhkajkjdhkjsdnam,sbkjahd ');
                    this.$bus.$emit('changeMaxImage', (ifUp > 0 ? "down" : "up"))
                }
                if (this.scrollStatus == '2') {
                    if (ifUp < 0 && this.scrollY == 0) {
                        this.testFun(1)
                        this.scrollStatus = '0'
                    }
                }
            }
            this.ifStop = false
            if (this.setTimeId !== '') {
                clearTimeout(this.setTimeId)
            }
            this.setTimeId = setTimeout(() => {
                this.ifStop = true
            }, 500);
        })
    },
    methods: {
        changeImage(val) {
            this.showImage = val
            console.log(val);
        },
        float(index) {
            setTimeout(() => {
                const dom = document.getElementsByClassName('tagContent' + index)[0]
                this.setId = setInterval(() => {
                    let num = Math.random() * 2 - 1
                    let num1 = Math.random() * 2 - 1
                    dom.style.transform = `translateX(${num}vw) translateY(${num1}vw)`
                }, 3000)
            }, (index - 1) * 500)
        },
        donghua() {
            this.status = 1
            this.scrollStatus = '0'
            const dom = document.getElementsByClassName('doorImage')[0]
            const dom2 = document.getElementsByClassName('index')[0]
            const dom1 = document.getElementsByClassName('line')[0]
            dom.style.transform = "rotateY(100deg)"
            dom2.style.transform = "scale(30)"
            dom1.style.maskPosition = "0vw 0vw"
            // dom1.style.transition = "3s ease"
            setTimeout(() => {
                dom2.style.opacity = "0"
            }, 1500)
            setTimeout(() => {
                clearInterval(this.setId)
                dom2.style.display = "none"
            }, 2500)
            window.scrollTo({
                top: 0,
                behavior: 'auto'
            });
        },
        hoverDoor() {
            if (this.status == 0) {
                const dom = document.getElementsByClassName('doorImage')[0]
                const dom1 = document.getElementsByClassName('line')[0]
                dom.style.transform = 'rotateY(30deg) skewY(-5deg)'
                dom1.style.maskPosition = "-9vw 0vw"
            }
        },
        outDoor() {
            if (this.status == 0) {
                const dom = document.getElementsByClassName('doorImage')[0]
                const dom1 = document.getElementsByClassName('line')[0]
                dom.style.transform = 'rotateY(0deg) skewY(0deg)'
                dom1.style.maskPosition = "-10.5vw 0vw"
            }
        },
       async testFun(data) {
            console.log(this.page,data>this.page);
            if (data > this.page) {
                const leftArr = document.getElementsByClassName('left')
                Object.values(leftArr).map((item) => {
                    item.style.width = '100vw'
                    item.style.opacity = '0.5'
                    item.style.left = '0vw'
                    setTimeout(() => {
                        item.style.width = '10vw'
                        item.style.opacity = '1'
                        item.style.left = '45vw'
                    }, 500);
                })
                const dom = document.getElementsByClassName('rotateBox' + (data - 1))[0]
                const dom1 = document.getElementsByClassName('rotateBoxBack' + (data - 1))[0]
                const dom2 = document.getElementsByClassName('box' + (data - 1))[0]
                const dom3 = document.getElementsByClassName('box' + (data))[0]
                dom.style.transform = dom1.style.transform = `rotateY(180deg)`
                setTimeout(() => {
                    dom2.style.zIndex = data
                }, 50);
                setTimeout(() => {
                    dom3.style.zIndex = data + 1
                }, 1000);
                this.page = data
            } else {
                const leftArr = document.getElementsByClassName('right')
                Object.values(leftArr).map((item) => {
                    item.style.width = '100vw'
                    item.style.opacity = '0.5'
                    item.style.left = '0vw'
                    setTimeout(() => {
                        item.style.width = '10vw'
                        item.style.opacity = '1'
                        item.style.left = '45vw'
                    }, 500);
                })
                const dom = document.getElementsByClassName('rotateBox' + (data + 1))[0]
                const dom1 = document.getElementsByClassName('rotateBoxBack' + (data + 1))[0]
                const dom2 = document.getElementsByClassName('box'+(data+2))[0]
                dom2.style.zIndex = 6-data-2
                dom.style.transform = dom1.style.transform =  await `rotateY(0deg)`
                this.page = data + 1
            }
        },
       scrollFuncition(scrollY) {
            const imageList = document.getElementsByClassName('imageList')[0]
            const lastImg = document.getElementsByClassName('romveX')[0]
            switch (this.scrollStatus) {
                case '-1':
                    break;
                case '0':
                    // this.testFun(this.page)

                    break;
                case '1':

                    break;
                case '2':
                    // console.log(scrollY);
                    if (imageList.getBoundingClientRect().bottom > document.documentElement.clientHeight) {
                        imageList.style.transform = `translateY(${-scrollY * .1}vw)`
                        // scrollY == 0 ? this.scrollStatus = '0' : this.scrollStatus = '2'
                    } else {
                        console.log(this.page);
                        this.testFun(4)
                        this.scrollStatus = '0'
                        imageList.style.transform = `translateY(0vw)`
                    }
                    break;
                case '3':

                    break;
                case '4':

                    break;
                case '5':
                    console.log(lastImg.style.transform);
                    lastImg.style.transform = `translateX(${-scrollY * .1}vw)`
                    break;
            }
            this.scrollY = scrollY
        },
    }
}
</script>
<style scoped>
.two {
    transform-origin: left;
    /* transform:  */
    transition: all 1s ease
}

.scaleImage {
    opacity: 0;
    display: none;
    transition: all 1s ease;
}

.maxBox>div {
    position: absolute;
}

.leftPage,
.rightPage {
    width: 50vw;
    display: inline-block;
    position: relative;
    top: 0%;
    height: 100vh;
    /* box-:  */
}

.tag {
    position: absolute;
    top: 40vw;
    left: 0;
}

.tagAllStyle {
    display: inline-block;
    font-size: .7vw;
    font-weight: 600;
    color: #73665e;
    /* transform:  */
    transition: all 3s ease;
}

.smallText {
    /* margin: 0 auto; */
    text-align: center;
    position: relative;
    top: 14.7vw;
    font-family: fangsong;
    color: #73665e;
    font-size: .8vw;
    font-weight: 600;
}


.nameText p {
    position: absolute;
    font-size: 4vw;
    font-family: fangsong;
    color: #73665e;
    margin-top: 1vw
}

.door {
    text-align: center;
    z-index: 999;
    position: relative;
}

.doorImage {
    width: 6.2vw;
    position: relative;
    top: 15vw;
    transform-origin: right;
    transition: all 1s cubic-bezier(0, -0.06, .04, .83);
    margin: 0 auto;
    /* : ; */
    left: -.34vw;
    transform: rotateY(0deg) skewY(0deg);
}

.index {
    z-index: 9999999999;
    transform-origin: 50.5vw 20vw;
    transition: 3s ease;
}

/* .doorImage:hover { */
/* transform: rotateY(30deg) skewY(-5deg); */
/* } */

.doorLine {
    width: 6.2vw;
    position: absolute;
    top: 15vw;
    left: 46.5vw;
    opacity: .7;
    filter: blur(.4vw)
}

.line {
    filter: blur(.2vw);
    width: 7vw;
    height: 4vw;
    position: absolute;
    transform: rotateX(180deg) skewY(30deg) rotateZ(-30deg);
    left: 45vw;
    top: 24.7vw;
    mask-position: -10.5vw 0vw;
    mask-repeat: no-repeat;
    mask-size: 10.5vw 4vw;
    mask-image: url('@/assets/image/door.png');
    transition: all 2s cubic-bezier(0, -0.06, .04, .83);
}

.doorBack {
    position: absolute;
    width: 6.7vw;
    top: 13vw;
    left: 44vw;
}

.doorBackWhite {
    position: absolute;
    width: 8.2vw;
    top: 16vw;
    left: 46vw
}
</style>
