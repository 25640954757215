<template>
    <div class="shadowBox">
        <!-- <div :class="`shadowBox1 ${isChange?'animeShadowLeft':''}`">
        </div> -->
        <div :class="`divShadow ${this.isLeft?'left':'right'}`"></div>
    </div>
</template>

<style scoped>
.divShadow {
    background: linear-gradient(to right, transparent, rgba(166, 153, 143, .5), transparent);
    width: 10vw;
    height: 100vh;
    left: 45vw;
    position: absolute;
    text-align: center;
    transition: .5s ease;
}
.shadowBox {
    position: absolute;
}
</style>
<script>
export default {
    name: 'shadow_name',
    components: {

    },
    props: {
        isLeft:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            isChange: false,
        }
    },

    mounted() {
        // const dom = document.getElementsByClassName('divShadow')[0]
        // this.$bus.$on('showShadwo', () => {
        //     dom.style.width = '50vw'
        //     dom.style.opacity = '0'
        //     setTimeout(() => {
        //         dom.style.width = '20vw'
        //         dom.style.opacity = '1'
        //     }, 500);
        // })
    },
    methods: {
    }
}
</script>