<template>
    <div style="height: 100vh;width: 100vw;background: #fdf9ed">
        <div class="noContent" style="background: #babbb3;width: 100vw;height: 3vw;">
            <p style="
            position: absolute;
            font-size: 3vw;
            color: #fdf9ed;
            top: -2vw;
            left: 2vw;
            text-shadow: .1vw .1vw 1vw rgba(0, 0, 0, .2);
            ">Nagi's choice & CIRCUS DOLL</p>
        </div>
        <p style="
        color: red;
    font-size: .8vw;
    margin: 5vw 15vw;
        ">Home > About > CIRCUS DOLL > Shops > Products > Payment </p>
        <div v-if="status == 2" style=" width: 25vw;
    margin-left: 37.5vw;height: 4vw;background: #fff;margin-bottom: 1vw;box-shadow: .1vw .1vw 1vw rgba(0,0,0,.1);">
            <img src="@/assets/image/lanzi.png" style="width: 3vw;margin: .5vw 2vw;display: inline-block;" alt="">
            <p
                style="font-size: .7vw;color: #9c9074;display: inline-block;top:-1vw;font-weight: 600;position: relative;">
                ご購入手続きは、あと少しで完了します。</p>
        </div>
        <div class="content" v-if="status!==3" :style="`margin-left:${status==1?'25vw':'37.5vw'}`">
            <div v-if="status == 1" style="height: 200vh;display: inline-block;">
                <div class="box1">
                    <div class="leftRenli">入力</div>
                    <div class="conterQueren">確認</div>
                    <div class="rightOver">完了</div>
                </div>
                <div class="box2" style="border-top: .1vw solid #cfcfc9;width: 80%;margin-left: 10%;">
                    <p>メールアドレス </p>
                    <input type="text" style="width: 98%;height: 1.9vw;border-radius: .2vw;border:.1vw solid #babbb3;">
                </div>
                <div class="box3">
                    <p style="font-weight: 600;font-size: .9vw;">お届け先情報</p>
                    <input placeholder="姓" type="text"
                        style="width: 45%;height: 1.9vw;border-radius: .2vw;border:.1vw solid #babbb3;display: inline-block;">
                    <input placeholder="名" type="text"
                        style="width: 45%;height: 1.9vw;border-radius: .2vw;border:.1vw solid #babbb3;display: inline-block;margin-left: 5%;">
                    <p style="margin-top: -0vw;"><span
                            style="margin-left: 35%;color: #babbb3;font-size: .6vw;">例）铃木</span><span
                            style="margin-left: 40%;color: #babbb3;font-size: .6vw;">例）太郎</span></p>
                    <input placeholder="陲便番号" type="text"
                        style="width: 98%;height:  1.9vw;border-radius: .2vw;border:.1vw solid #babbb3;">
                    <p style="margin-top: 0;float: right;color: #babbb3;font-size: .6vw">例）1063555</p>
                    <select
                        style="width: 100%;height: 2vw;border-radius: .2vw;border:.1vw solid #babbb3;font-size: .7vw;margin-bottom: .8vw">
                        <option value="test1">哈得尽阿萨大大快1</option>
                        <option value="test2">哈得尽阿萨大大快2</option>
                        <option value="test3">哈得尽阿萨大大快3</option>
                        <option value="test4">哈得尽阿萨大大快4</option>
                    </select>
                    <input placeholder="番地 建筑名 部屋番号" type="text"
                        style="width: 98%;height: 1.9vw;border-radius: .2vw;border:.1vw solid #babbb3;">
                    <p style="margin-top: 0;float: right;color: #babbb3;font-size: .6vw">例）京东卡江哈斯骄傲的卡号</p>
                    <input placeholder="电话番号" type="text"
                        style="width: 98%;height: 1.9vw;border-radius: .2vw;border:.1vw solid #babbb3;">
                    <p style="margin-top: 0;float: right;color: #babbb3;font-size: .6vw">例）15432134325</p>
                </div>
                <div class="box4">
                    <p style="font-weight: 600;font-size: 1vw;">お支払い方法</p>
                    <div
                        style="width: 100%;height: 9vw;background: #fdf9f5;border-radius: .2vw;border: .1vw solid #babbb3">
                        <div
                            style="width: 100%;height: 2vw;background: #fff;border-radius: .2vw .2vw 0 0;border-bottom: .1vw solid #babbb3">
                            <el-radio v-model="radio" style="margin: .5vw;" label="1" size="medium"><i
                                    style="color: #9c9074;">PAYPAL</i></el-radio>
                        </div>
                        <img src="@/assets/image/paypalLogo.png" style="width: 30%;margin:.5vw 35% 0vw;" alt="">
                        <div
                            style="width: 90%;font-size: .7vw;overflow-wrap: break-word;word-break: break-all;line-height: .8vw;margin-left: 5%">
                            <p>※PayPalに登録いただいているデビットカードやクレジットカード、銀行口座等で、
                                お支払いすることが可能となります。PayPalとは↗ #https://www.paypal.com/jp/webapps/mpp/personal＃</p>
                        </div>
                    </div>
                    <div style="width: 100%;border-radius: .2vw;border: .1vw solid #babbb3;height: 2vw;margin-top: 1vw">
                        <el-radio v-model="radio" style="margin: .5vw;" label="2" size="medium"><i
                                style="color: #9c9074;">銀行振り込み（ゆうちょ銀行 | 三菱UFJ）</i></el-radio>
                    </div>
                </div>
                <div class="box5">
                    <p style="font-weight: 600;font-size: .9vw;">備考欄</p>
                    <!-- <input type="textarea" placeholder="请输入文本"></input> -->
                    <textarea rows="6" placeholder="ご要望がありましたら入力してください。" type="text"
                        style="width: 98%;border-radius: .2vw;border:.1vw solid #babbb3;">
                    </textarea>
                </div>
                <div class="box6">
                    <button @click="status = 2"
                        style="border: none;border-radius: .2vw;background: #e1d0a7;color: #fff;width: 80%;height: 3vw;margin-top: 2vw;">入力内容の確認へ</button>
                    <p style="color: #e1d0a7;">‹ ショップに戻る</p>
                </div>
            </div>
            <payPageTwo @ok="status =3" v-if="status == 2" />
        </div>
        <div v-if="status == 1" class="content2"
            style="width: 18vw;background:#fff;display: inline-block;position: relative;top: -48vw;left: 51vw;box-shadow: 0 .1vw 1vw rgba(0,0,0,.1);">
            <p style="width: 100%;position: relative;margin:2vw 40%;color: #d1cbbe;">‹ ショップに戻る</p>
            <img src="@/assets/image/NA.png" style="width: 3vw;position: absolute;right: 1vw;top:1vw;" alt="">
            <p style="float: right;margin:0 1vw 1vw;font-size: .8vw;">カートに入っている商品</p>
            <div style="margin-top: 4vw;position: relative;">
                <div v-for="(item, index) in carList" :key="item" class="carGoods"
                    :style="`border-bottom:${index == carList.length - 1 ? '.1vw solid #cfcfc9' : 'none'}`">
                    <el-image :src="item.url" style="
                        width: 35%;height: 5vw;
                        position: relative;
                        top: 50%;display: inline-block;
                        transform: translateY(-50%);
                        " fit="cover">
                    </el-image>
                    <div style="display: inline-block;width: 60%;margin-left: 5%">
                        <p style="font-size: .7vw;overflow-wrap: break-word;word-break: break-all;line-height: .8vw;">{{
                            item.title }}</p>
                        <p>{{ '￥' + item.price }}</p>
                        <p style="position: absolute;right: 0vw;top: 60%;">{{ 'x' + item.num }}</p>
                    </div>
                </div>
                <div class="coupon">
                    <span style="font-size: .5vw;">クーポン</span>
                    <input placeholder="クーポンコード" />
                    <button>使用</button>
                </div>
                <div class="Notes">
                    <!-- <p>小计</p> -->
                    <el-row style="width: 100%">
                        <p style="display: inline-block;float: left;">小计</p>
                        <p style="display: inline-block;float: right;">
                            {{ '￥' + notesFun() }}
                        </p>
                    </el-row>
                    <el-row style="width: 80%;position: absolute;margin-top: -1vw;">
                        <p style="display: inline-block;float: left;">送料</p>
                        <p style="display: inline-block;float: right;">
                            {{ '￥' + fare }}
                        </p>
                    </el-row>
                </div>
                <div class="totle">
                    <el-row>
                        <p>合计</p>
                        <p style="float: right;">{{ '￥' + (notesFun() + fare) }}</p>
                    </el-row>
                </div>
            </div>
        </div>
        <div v-if="status ==3" style="text-align: center;">
            <p style="font-size: 2vw;color: #ebdcb9;">注文完了</p>
            <div class="back"></div>
            <img src="@/assets/image/overImg.png" style="z-index: 1;position: relative;width: 20vw;" alt="">
            <p style="font-size: 1.5vw;color: #ebdcb9;">ご注文ありがとうございます</p>
            <button style="background: #e1d0a7;border: none;color: #fff;border-radius: .2vw;width: 10vw;height: 2vw;">ホームに戻る</button>
        </div>
    </div>
</template>
<style scoped>
.back{
    width: 20vw;
    height: 18vw;
    mask-image: url('@/assets/image/overImg.png');
    mask-size: 20vw 20vw;
    position: absolute;
    background: #fff;
    left: 42vw;
    z-index: 0;
}
.leftRenli {
    mask-image: url('@/assets/image/leftRenLi.png');
    mask-size: 100% 3vw;
    width: 33%;
    mask-repeat: no-repeat;
    background: #c1c6c0;
    height: 3vw;
    display: inline-block;
    color: #fff;
    margin-right: -.7vw;
}

.conterQueren {
    mask-image: url('@/assets/image/conterQueRen.png');
    mask-size: 100% 3vw;
    display: inline-block;
    width: 33%;
    mask-repeat: no-repeat;
    background: #f3f3f2;
    color: #c1c6c2;
    height: 3vw;
}

.rightOver {
    mask-image: url('@/assets/image/rightOver.png');
    mask-size: 100% 3vw;
    display: inline-block;
    width: 33%;
    mask-repeat: no-repeat;
    background: #f3f3f2;
    height: 3vw;
    color: #c1c6c2;
    margin-left: -.7vw;
}

.box1 {
    width: 90%;
    margin-left: 5%;
    line-height: 2.5vw;
    font-size: .8vw;
    margin-top: 1vw;
    text-align: center;
}

.box2>p {
    font-size: .7vw;
    font-weight: 600;
}

input::placeholder {
    font-size: .7vw;
    padding-left: .5vw;
    color: #babbb3;
}

.box3 {
    width: 80%;
    margin-left: 10%;
    font-size: .8vw;
    margin-top: 1vw;
}

.box4 {
    width: 80%;
    margin-left: 10%;
    font-size: .8vw;
    margin-top: 1vw;
}

.box5 {
    width: 80%;
    margin-left: 10%;
    font-size: .8vw;
    margin-top: 1vw;
}

.box6 {
    width: 80%;
    margin-left: 10%;
    font-size: .8vw;
    text-align: center;
    margin-top: 1vw;
}

.content {
    background: #fff;
    width: 25vw;
    margin-left: 25vw;
    height: 100vh;
    box-shadow: .5vw .2vw 1vw rgba(0, 0, 0, .1);
    overflow: scroll;
}

.content::-webkit-scrollbar {
    width: .1vw;
}

.content2 {
    font-size: .7vw;
    font-weight: 600;
}

.carGoods {
    width: 80%;
    margin-left: 10%;
    height: 7vw;
    position: relative;
    /* background: red; */
    border-top: .1vw solid #cfcfc9;
}

.coupon {
    width: 80%;
    margin-left: 10%;
    height: 3vw;
    border-bottom: .1vw solid #cfcfc9;
    line-height: 3vw
}

.coupon>input {
    margin-left: 5%;
    height: 1.5vw;
    width: 60%;
    border: .1vw solid #babbb3;
    border-radius: .3vw 0 0 .3vw;
    border-right: none;
    display: inline-block;
}

.coupon>button {
    height: 1.73vw;
    padding-bottom: .2vw;
    border-radius: 0 .3vw .3vw 0;
    border: .1vw solid #babbb3;
    /* border-left:none ; */
}

.content2 {
    overflow: scroll;
    height: 30vw;
}

.content2::-webkit-scrollbar {
    width: .1vw;
}

.Notes {
    width: 80%;
    margin-left: 10%;
    height: 4vw;
    border-bottom: .1vw solid #cfcfc9;
}

.totle {
    width: 80%;
    margin-left: 10%;
    height: 8vw;
}

.totle p {
    display: inline-block
}
</style>
<script>
import payPageTwo from '@/components/payPageTwo.vue'
export default {
    name: "payPageName",
    components: { payPageTwo },
    props: {
    },
    data() {
        return {
            radio: '1',
            status: 1,
            carList: [
                {
                    url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                    num: 2,
                    title: '测试cecshi/test the text/jhjakjsjk/2134567862132',
                    price: '18000',
                },
                {
                    url: 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                    num: 1,
                    title: '测试cecshi/test the text/jhjakjsjk/2134567862132/第二个',
                    price: '18',
                },
                {
                    url: 'https://img1.baidu.com/it/u=1387493974,4133239259&fm=253&fmt=auto?w=800&h=1149',
                    num: 1,
                    title: '测试cecshi/test the text/jhjakjsjk/2134567862132/第二个',
                    price: '18',
                },
            ],
            fare: 1,
        }
    },
    mounted() {
        console.log(this.$route.query,'test');
        document.body.style.height = '100vh'
        window.scrollTo({
            top: 0,
            behavior: 'auto' // 平滑滚动
        });
    },
    methods: {
        notesFun() {
            var data = 0
            this.carList.map((item) => {
                data += item.price * item.num
            })
            return data
        }
    }
}
</script>
