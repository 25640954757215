<template>
  <div>

  </div>
</template>
<style scoped></style>
<script>

export default {
  name: 'aboutName',
  props: {
  },
  data() {
    return {
      text: ''
    }
  },
  mounted() {
    // 邮箱验证
   

  },
  methods: {
  }
}
</script>
