<template>
  <!-- <router-view></router-view> -->
  <index_nagi ref="index_nagi"  />
</template>

<script>
import index_nagi from './view/index_nagi.vue'
export default {
  name: 'App',
  components: {
    index_nagi
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  padding: 0 !important;
  margin: 0 !important;
}
</style>
