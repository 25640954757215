<template>
    <div style="position: relative">
        <div class="contentBackground">
            <img src="@/assets/image/contentBackground.png" id="firstImage" style="width: 100vw;margin-top: 47.7vw"
                alt="">
        </div>
        <div class="transform1"
            style="position: absolute;z-index: 90;left: 61vw;top: 94vw;width: 30vw;transition: 1s ease;transform: scale(0.5) rotateZ(720deg);">
            <img src="@/assets/image/textBackground.png" style="width: 30vw;" alt="">
            <!-- <span style="">凪とは、<br> 風がやんで、波が穏やかになることを指す言葉。</span> -->
            <p style="position: relative;top: -14vw;line-height: 5vw;font-size: .9vw;text-align: center;">
                <span style="font-size: 2vw;left: -7.5vw;position: relative;color: #a78f7d;font-family:">凪</span><span
                    style="position: relative;left: -7.2vw;">とは、</span><br> 風がやんで、波が穏やかになることを指す言葉。
            </p>
        </div>
        <div style="position: absolute;z-index: 90;left: 10vw;top: 116vw;width: 25vw;transition: 1s ease;transform: translateX(-100vw);"
            :class="`${show1 ? '' : 'transform3'}`">
            <img src="@/assets/image/textBackground.png" style="width: 25vw;" alt="">
            <!-- <span style="">凪とは、<br> 風がやんで、波が穏やかになることを指す言葉。</span> -->
            <!-- <p style="position: relative;top: -14vw;line-height: 5vw;font-size: .9vw;text-align: center;">
                <span style="font-size: 2vw;left: -7.5vw;position: relative;color: #a78f7d;">凪</span><span style="position: relative;left: -7.2vw;">とは、</span><br> 風がやんで、波が穏やかになることを指す言葉。
            </p> -->
            <p style="position: relative;top: -11vw;line-height: 2vw;font-size: .9vw;text-align: center;">
                モノや情報に溢れてる今は、<br>何を大切に生きたいのか、<br>一瞬一瞬を大事にするには何が必要か、<br>自分自身に問いかける。
            </p>
        </div>
        <div class="chazihua" style="position: absolute;z-index: 91;left: 31vw;top: 122vw;height: 12vw;">
            <img src="@/assets/image/chazihua.png" style="width: 12vw;transition: 1s ease;" class="transform2" alt="">
        </div>
        <div class="maozihua.png" style="position: absolute;z-index: 91;left: 8vw;top: 128vw;">
            <img src="@/assets/image/maozihua.png" style="width: 12vw;transition: 1s ease;" alt="" class="transform2">
        </div>
        <div class="text" style="position: absolute;font-size: .9vw;top: 130vw;left: 57vw;">
            <div v-for="item in 4" :key="item">
                <p>浮いた思考と時間を </p><span class="textSpan" :style="`top:${item * 2 - .6}vw`"></span>
            </div>
            <!-- <p>好きなことに使うこと。</p>
            <p>変化し続ける日々に、</p>
            <p> 小さな癒やしみつけること。 </p> -->
        </div>
        <div class="sixian" style="position: absolute;z-index: 89;top: 100vw;left: -10vw">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1531 820" width="1531" height="820"
                preserveAspectRatio="xMidYMid meet" style="width: 100vw; height: 50vw;">
                <path :class="path1 ? '' : 'path1'" fill="none" stroke="black" stroke-width="1" stroke-dashoffset="2400"  stroke-dasharray="2400"
                    d=" M1535,-5.5 C1535,-5.5 1519.6600341796875,27.632999420166016 1460.5,46.25 C1389,68.75 1333.5,114 1368.75,171.49899291992188 C1384.4150390625,197.05099487304688 1409.75,211.25 1393.54296875,246.2740020751953 C1383.5350341796875,267.9010009765625 1355.5,277.5 1314,273 C1277.64794921875,269.0580139160156 1233.3709716796875,259.4469909667969 1210.97705078125,289.93499755859375 C1192.75,314.75 1194.75,346 1225.25,402.75 C1239.3709716796875,429.02398681640625 1244.5140380859375,466.25 1226.10400390625,492.1990051269531 C1200.1650390625,528.7620239257812 1162.5439453125,538.1190185546875 1144.6109619140625,540.68701171875 C1111,545.5 1066.5,542.25 1037.75,529 C1015.9860229492188,518.968994140625 1015,501 1023.2109985351562,495.41900634765625 C1033.97802734375,488.10101318359375 1060.260986328125,502.29400634765625 1070.25,516.5 C1081.5,532.5 1087.4849853515625,599.885009765625 1001.5,610.5 C961,615.5 929.0059814453125,606.176025390625 857.3309936523438,596.2139892578125 C799.22998046875,588.1389770507812 699,584.5 655,591 C618.0089721679688,596.4650268554688 583.64599609375,604.9660034179688 552.5189819335938,618.9879760742188 C501.55499267578125,641.9450073242188 457.58599853515625,676.3380126953125 412.5,711.5 C333,773.5 238,808.5 164.5,814 C85.4739990234375,819.9140014648438 15.5,813 -16.5,799">
                </path>
            </svg>
        </div>
        <div class="shuText" style="position: absolute;top: 153vw;left: 30vw;">
            <div style="top:-7vw;height: 5.5vw;">
                <span :style="`top:${index}vw`" class="text1Span" v-for="(item, index) in text1" :key="item">
                    {{ item }}
                </span>
            </div>
            <div style="top:-3.5vw;height: 9vw;">
                <span :style="`top:${index}vw`" class="text1Span" v-for="(item, index) in text2" :key="item">
                    {{ item }}
                </span>
            </div>
        </div>
        <div class="showImgArr"
            style="position: absolute;top: 135vw;left: 57vw;z-index: 90;opacity: 0;filter: blur(.3vw);transition: 2s ease;">
            <img src="@/assets/image/showImgArr.png" style="width: 35vw;" alt="">
        </div>
        <div class="contentBackgroundTwo">
            <img src="@/assets/image/contentBackground2.png" style="width: 100vw;margin-top: -7vw;" alt="">
        </div>
    </div>
</template>
<style scoped>
.path1 {
    animation: path1 5s forwards;

}

@keyframes path1 {
    0% {
        stroke-dashoffset: 2400;
        stroke-dasharray: 2400;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 2400;
    }
}

.text1Span {
    animation: text1Span .5s forwards;
    position: absolute;
    left: 0;
}

@keyframes text1Span {
    0% {
        opacity: 0;
        filter: blur(.3vw);
        transform: rotateZ(90deg)
    }

    100% {
        filter: blur(0vw);
        opacity: 1;
        transform: rotateZ(0deg)
    }
}

.transform3 {
    animation: show1 1s forwards;
}

@keyframes show1 {
    0% {
        transform: translateX(-100vw);
    }

    80% {
        transform: translateX(5vw);
    }

    100% {
        transform: translateX(0);
    }
}

.enTextSvg path {
    fill: none;
    stroke-width: 5;
    stroke: #ca6a51;
}

.showList {
    scrollbar-width: none;
}

.allLogo svg {
    position: relative;
    top: .6vw;
    margin-left: .7vw;
    width: 2vw
}

.contentBackground img {
    box-shadow: 0vw -1vw 3vw 0 rgba(0, 0, 0, 0.5);
}

.showF {
    /* translate: translateY(5deg) */
    transform: rotateZ(10deg)
}

.showT {
    transform: rotateZ(-10deg)
}

.shuText div {
    display: inline-block;
    position: relative;
    margin-left: 1vw;
    width: 1vw;
    color: #fff;
    text-align: center;
    background: #b6a082;
    /* 金底色    */
    font-size: .9vw;
    font-weight: 600;
    line-height: 1.5vw;
    /* background: #c2bcbc;  */
    /* 灰底色 */
}

.text p {
    /* background: linear-gradient(to top ,#fff ,rgba(0,0,0,0),rgba(0,0,0,0)); */
    /* height: .7vw; */
    /* margin-bottom: 1vw; */
    z-index: 2;
    position: relative;
    font-family: 'regular';
}

.maxBox img {
    position: relative;
}

.text span {
    width: 2vw;
    background: #fff;
    height: 1vw;
    display: block;
    position: absolute;
    /* top: 1.4vw; */
    z-index: 1;
}
</style>
<script>
import anime from 'animejs/lib/anime.es.js';

export default {
    name: 'index_twice',
    props: {
    },
    data() {
        return {
            isShow: false,
            show1: true,
            text1: '',//生活から、
            text2: '',//なにか物足りない
            text1Status: true,
            path1: true,
        }
    },
    watch: {
        text1Status(val) {
            if (val == false) {
                const text1 = '生活から、'
                const text2 = 'なにか物足りない'
                let i = 0
                let setId = setInterval(() => {
                    if (i < text1.length) {
                        this.text1 += text1[i]
                    }
                    this.text2 += text2[i]
                    i++
                    if (i >= text2.length) {
                        clearInterval(setId)
                    }
                }, 100);
            }
        }
    },
    mounted() {
        this.$bus.$on('path1', () => {
            this.path1 = false
        })
        this.$bus.$on('show1Fun', (e) => {
            this.show1 = e
        })
        this.$bus.$on('text1', () => {
            this.text1Status = false
        })
        document.body.style.height = `441vw`
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        })
        anime({
            targets: '.showF',
            rotateZ: [-10, 10],
            loop: true,
            direction: 'alternate',
            autoplay: true,
            easing: 'easeInOutSine'

        })
        anime({
            targets: '.showT',
            rotateZ: [10, -10],
            loop: true,
            direction: 'alternate',
            autoplay: true,
            easing: 'easeInOutSine'
        })
        setTimeout(() => {
            this.isShow = true
        }, 4000);
    },
    methods: {
    }
}
</script>
