<template>
    <div style="transform-style: preserve-3d;
    perspective: 90vw;" class="box4" >
        <!-- <div>test</div> -->
        <div class="rotateBox4" @click="fourClick(5)" >
        <shadow_name style="left: -50vw;" ref="shadow_name" :isLeft="false" />
            <img src="@/assets/image/backXian.png" alt="" class="backXian">
            <img src="@/assets/image/imgListBack.png" alt="" class="imgListBack">
            <div class="imgList">
                <div v-for="(item,index) in nagiImgList" :key="item" style="display: inline-block;position: relative;">
                    <img  :src="item" :class="`imageListNagi list${index}`" >
                    <span style="font-size: .4vw;position:absolute;left: 1vw;text-align: center;display: inline-block;bottom: -.4vw;">{{ 'test'+index }}</span>
                </div>
            </div>
        </div>
        <div class="rotateBoxBack4" @click="fourClick(3)">
        </div>
    </div>
</template>
<style scoped>
.imageListNagi{
    height: 4vw;
    position: relative;
    margin: 0.6vw .5vw;
}
.imgList{
    position: absolute;
    width: 40vw;
    height: 6.2vw;
    left: 8vw;
    bottom: 1vw;
}
.imgListBack{
    position: absolute;
    width: 40vw;
    bottom: 1vw;
    left: 5vw;
}
.backXian{
    position: absolute;
    width: 49vw;
    height: 100vh;
}
.rotateBox4{
    width: 50vw;
    height: 100vh;
    background: #e6e3da;
    z-index: 5;
    position: absolute;
    transform-origin: left;
    overflow: hidden;
    transition: 1s ease;
    backface-visibility: hidden;
    /* box-shadow: -1vw 0vw 10vw rgba(0,0,0,.5); */
}
.rotateBoxBack4{
    background: #e1dbd4;
    width: 50vw;
    height: 100vh;
    position: absolute;
    transform-origin: left;
    z-index: 4;
    box-shadow: 1vw 0 1vw rgba(0,0,0,.5);
    transition: 1s ease;
}
</style>
<script>
import shadow_name from '../../components/shadow.vue'

export default {
    components: {
        shadow_name,
    },
    props: {
    },
    data() {
        return {
            nagiImgList:[
                require('@/assets/test/test.png'),
                require('@/assets/test/test4.jpg'),
                require('@/assets/test/test2.png'),
                require('@/assets/test/test2.png'),
                require('@/assets/test/test2.png'),
                require('@/assets/test/test2.png'),
                require('@/assets/test/test3.png'),
            ]
        }
    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'auto' 
        });
    },
    methods: {
        fourClick(index){
            this.$bus.$emit('testChange',index)
            // if(index==3){
            //     const dom = document.getElementsByClassName('box5')[0]
            //     dom.style.zIndex = '1'
            // }
        },
    }
}
</script>
