const contData = {
    bookText: [
        '「ドールも好き、友達も好き、',
        '一人はこわいけど、',
        '私たち二人ならなんか面白そう。」',

        '■ただそう思って、',
        '#CIRCUS DOLL#を始めました。'
    ],
    conterText: [
        'そして、人と人を繋げられる、',
        '素敵な作品たくさん揃えられる',
        'いつかそんなサーカス（小さな舞台）を作りたい。',

        '自分の作品だけではなく、この小さなサーカスは、',
        '中日作家さんの作品をつなぐ架け橋になりたいと目指しています。',

        'ご相談承って',
    ],
    changeBoxContentTextLeft: [
        'なぎに「なぜCircus Dollを一緒にやりたいの」と聞かれたので、自分の気持ちを書いてみたいと思います。',
        'しかし最初に思ったことからいままで感じた気持ち、大切な思い出がたくさんあったので、ちゃんと文を',
        'まとめるかどうかも心配なんですが、よろしくお願いします。',

        '記憶を辿ってみましたら、やろうと思った大きいな原因は２つありました。',

        '１．昔からずっとお人形が好きで、デザインが夢で、',
        'お人形のために服を作って夢中になることが好きだから。',
        '２．ただただNagiのことが好きだから。',

        'なぎとまだ知り合いだったの時、なぎへ印象は、誠実で優しく、可愛らしい見た目の上に芸術的な雰囲',
        '気でファッションセンスもありながら、何にをやっても上手くこなす万能美少女でした。時間につれて',
        '話せば話すほど、なぎの色んな顔を知るほど魅力的を感じてしまう。自分との性格も似ている部分が多',
        'く、まるで世界のもう一人別の姿の自分を眺めているような、たくさん共感を得た。',

        'なぎの手作り作品や、写真と動画を撮る雰囲気がすべて私の好みで、その上多くのスキルを持っている',
        'なぎに憧れが止みませんでした、もっと距離を近づきたい、仲良くなりたいと思いつつも、性格が内向',
        '的な自分は、積極的に踏み出すことはできませんでした。',

        'そんな時、なぎから「もしよければ、一緒にブランドをやってみない？」と声かけられ、その時の私に',
        'とって、本当に感動的でワクワクが止まりませんでした。',

        '自分の波長と合うパートナーと出会う確率はすでに低い上難しいことなのに、さらに尊敬し好きな人と',
        '一緒に仕事ができるなんて…！言葉もできないほど驚きと喜びでした。',
    ],
    changeBoxContentTextConter: [
        'なぎに「ちゃんとできるかどうが不安だ」と言って、',
        'なぎは、「その不器用さまで抱えて頑張りたい、',
        'それこそ私たちじゃないかな」と言った。',

        'そう…。それこそ私たちです。未熟でもやること一つ一つ丁寧に全うしたい、',
        '不器用ながらもチャレンジしてみたいです、',
        '一回目の人生、クレイジーだっていいじゃないか、夢は描こうと思いました。',
    ],
    changeBoxContentTextRightTop: [
        'グレイテスト・ショーマンという映画のインスピレーションを受け、#CIRCUS  DOLLは#',
        '生まれました。私たち二人とも大好きな映画で、共鳴しました。',

        '「好きのものこそ上手なれ」、「本当に好き」を大切にすることこそ私の役目だと思い',
        'ました。このワクワクさをどう人に共有するかによって芸術は生まれたと思いました。',
    ],
    changeBoxContentTextRightBottom: [
        'They can say, they can say it all sounds crazy',
        '狂ったって、みんな言うかもしれない',

        "They can say, they can say I've lost my mind",
        '正気を失ったと、みんな思うかもしれない',

        "I don't care, I don't care, so call me crazy",
        'でも僕は気にしないよ',

        'We can live in a world that we design',
        '僕たちが創り上げた世界に住めるんだから'
    ]

}

export default contData